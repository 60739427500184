<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Custom Email Template</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.form" class="form-wrapper">
                <form-group label="Event Trigger" class="event-trigger no-underline">
                    <md-input id="custom-template-eventTriggerName-input" :value="eventTriggerNameStatus" readonly />
                </form-group>
                <form-group label="Fields" class="custom-merge-field">
                    <drop-down>
                        <md-button @click.prevent slot="title" class="dropdown-toggle md-info" data-toggle="dropdown">
                            Insert Merge Field
                        </md-button>
                        <ul class="dropdown-menu dropdown-menu-right dropdown-menu-height">
                            <li v-for="field in mergeFields" :key="field.key">
                                <span
                                    v-if="field.key === 'stopcustomfields' || field.key === 'shipmentcustomfields'"
                                    class="custom-field-header"
                                >
                                    {{ field.name }}
                                </span>
                                <a v-else @click="insertMergeField(field.key)">
                                    {{ field.name }}
                                </a>
                            </li>
                        </ul>
                    </drop-down>
                </form-group>
                <form-group class="custom-textarea" name="template">
                    <md-textarea v-model="form.template" ref="template" />
                </form-group>
            </form-wrapper>
            <br />
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="updateTemplate">Save</md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required } from 'vuelidate/lib/validators';
import { TEAM_CUSTOM_ACTION_STOP_MERGE_FIELDS } from '@/utils/CustomActionConstants';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'EmailTemplateModal',
    props: {
        customAction: { type: Object, required: true },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    mixins: [GeneralMixin],
    data() {
        return {
            form: {
                template: ''
            },
            stopMergeFieldsOptions: [...TEAM_CUSTOM_ACTION_STOP_MERGE_FIELDS],
            stopCustomFields: []
        };
    },
    validations: {
        form: {
            template: {
                required
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        }),
        eventTriggerNameStatus() {
            const triggerLabel = this.customAction.eventTriggerName;
            let statusLabel = '';
            if (this.customAction.filter && this.customAction.filter.status && this.customAction.filter.status.length) {
                statusLabel = ` - (${this.customAction.filter.status.join(', ')})`;
            }
            return `${triggerLabel}${statusLabel}`;
        },
        mergeFields() {
            return this.stopMergeFieldsOptions || [];
        }
    },
    methods: {
        ...mapActions('custom-actions', ['UPDATE_EVENT_TRIGGER']),
        getMergeFields() {
            if (this.form.stopType === 'Pick Up' || this.form.stopType === 'Drop') {
                this.mergeFields = this.insertMergeFieldOptions
                    .concat(this.insertShipmentMergeFieldOptions)
                    .concat(this.stopCustomMergeFields)
                    .concat(this.shipmentCustomMergeFields);
            } else {
                this.mergeFields = this.insertMergeFieldOptions.concat(this.stopCustomMergeFields);
            }
        },
        insertMergeField(value) {
            const textarea = this.$refs.template.$el;

            if (value !== 'default') {
                if (!this.form.template) {
                    this.form.template = '';
                }
                const temp = this.form.template || '';
                const currentCursorPosition = textarea.selectionStart;
                this.form.template = `${temp.substring(0, textarea.selectionStart)}{{${value}}}${temp.substring(
                    textarea.selectionEnd,
                    temp.length
                )}`;

                this.$nextTick(() => {
                    textarea.focus();
                    // The additional + 4 is because we are adding {{}} to the merge field value upon insert.
                    const cursorfocusPos = currentCursorPosition + value.length + 4;
                    textarea.setSelectionRange(cursorfocusPos, cursorfocusPos);
                });
            }
        },
        getStopCustomFields() {
            const api = '/api/teams/custom-fields/stops';
            handleRequests(api).then((response) => {
                if (response.data.length > 0) {
                    this.stopCustomMergeFields.push({
                        name: 'Stop Custom Fields',
                        key: 'stopcustomfields'
                    });
                    response.data.forEach((field) => {
                        this.stopCustomMergeFields.push({
                            name: field.label,
                            key: field.name
                        });
                    });
                }
                this.getMergeFields();
            });
        },

        async updateTemplate() {
            this.$v.form.$touch();
            if (this.$v.form.$invalid) 
                return;

            this.$_handleLoaderState(true, 'UPDATING EMAIL TEMPLATE...');

            try {
                const model = { ...this.customAction };
                model.settings.emailTemplateContent = this.form.template || '';

                await this.UPDATE_EVENT_TRIGGER({ data: model });

                this.$notify({
                    message: 'Successfully updated an email template.',
                    type: 'success'
                });

                this.resolve({ result: 'ok' });
            } catch (e) {
                const message = 'Could not update email template.';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        }
    },
    mounted() {
        this.form.template = this.customAction.settings.emailTemplateContent;
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    max-width: 620px;
}

.custom-textarea {
    margin-top: 5px !important;
}

.event-trigger {
    float: left;
    width: 350px;
    margin-top: 29px;
    margin-right: 10px;
    ::v-deep label {
        top: -1.2rem !important;
    }
}
.custom-merge-field {
    justify-content: flex-end;
    margin: 15px 0 0 auto;
    width: 188px;
    float: right;

    ::v-deep label {
        top: -5px !important;
        font-size: 0.6875rem;
    }

    .dropdown {
        margin-left: 0;
        margin-top: 12px;
        .md-button {
            background-color: #00bcd4 !important;
            margin: 0;

            ::v-deep .md-button-content {
                color: #fff !important;
            }

            ::v-deep .md-ripple:after {
                color: #fff !important;
            }
        }
    }

    .dropdown-menu-height {
        max-height: 400px;
        overflow-y: scroll;
    }
    .custom-field-header {
        padding-left: 10px;
        font-weight: bold;
    }
}
.custom-merge-field:after,
.custom-merge-field:before {
    height: 0;
}
.modal-body {
    padding-bottom: 0;
    padding-top: 10px;
}

::v-deep div.md-field.no-underline:after {
    height: 0px;
}
</style>
